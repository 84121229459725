import { config } from 'src/Constanturl';
import { createStore } from 'redux';
var url = config.url.API_URL
var url_users = config.url.API_URL_USERS
const initialState = {
  sidebarShow: true,
  asideShow: false,
  theme: 'default',
  apiurl:config.url.API_URL
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

const store = createStore(changeState)
export default store
