const prod = {
  url: {
   API_URL: 'https://www.immicomp.com',
   API_URL_USERS: 'https://www.immicomp.com'
  }
 };
 const dev = {
  url: {
   API_URL: 'http://localhost/immicompliance_api'
  }
 };
 export const config = process.env.NODE_ENV === 'development' ? dev : prod;