import React, { Component, Suspense } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './scss/style.scss'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/Authentication/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const EmailApp = React.lazy(() => import('./views/apps/email/EmailApp'))

// Email App

/*const EmailApp = React.lazy(() => import('./views/apps/email/EmailApp'))
<Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route path="/apps/email/*" name="Email App" element={<EmailApp />} />*/

         
class App extends Component { 

  render() {
    return (
      <Router basename={process.env.BASE_URL}>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/" name="Login Page" element={<Login />} />
            <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route path="/apps/email/*" name="Email App" element={<EmailApp />} />
            <Route path="*" name="Home" element={<DefaultLayout />} />    
          </Routes>
        </Suspense>
      </Router>
    )
  }
}

export default App
